import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const baseTitle = 'FIRA';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./features/sign-in-b2c/sign-in-b2c.component').then(
        (m) => m.SignInB2cComponent,
      ),
    title: `Entrar - ${baseTitle}`,
  },
  {
    path: 'r',
    loadChildren: () =>
      import('./shared/components/templates/base/base.routes').then(
        (m) => m.routes,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'something-has-happened',
    loadComponent: () =>
      import(
        './core/components/something-has-happened/something-has-happened.component'
      ).then((m) => m.SomethingHasHappenedComponent),
    canActivate: [MsalGuard],
  },
  { path: '**', redirectTo: '' },
];
