import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_fira-signup-signin',
    editProfile: 'B2C_1_profile-editing',
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${environment.tenantName}.b2clogin.com/${environment.tenantName}.onmicrosoft.com/B2C_1_fira-signup-signin`,
    },
    editProfile: {
      authority: `https://${environment.tenantName}.b2clogin.com/${environment.tenantName}.onmicrosoft.com/B2C_1_profile-editing`,
    },
  },
  authorityDomain: `${environment.tenantName}.b2clogin.com`,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.applicationId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: () => {
        // console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  localApi: {
    endpoint: 'http://localhost:3000/api',
    scopes: environment.scopes,
  },
  hmlApi: {
    endpoint: 'https://prod-eus-apim-001.azure-api.net/hml/api',
    scopes: environment.scopes,
  },
  prodApi: {
    endpoint: 'https://prod-eus-apim-001.azure-api.net/api',
    scopes: environment.scopes,
  },
};

export const loginRequest = {
  scopes: [],
};
