import {
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  PreloadAllModules,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withPreloading,
} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { NzIconModule } from 'ng-zorro-antd/icon';

import {
  UserOutline,
  LockOutline,
  EyeInvisibleOutline,
  WindowsFill,
  DashboardOutline,
  InboxOutline,
  AuditOutline,
  WarningOutline,
  SafetyOutline,
  DotChartOutline,
  FileProtectOutline,
  DollarOutline,
  AppstoreAddOutline,
  SettingOutline,
  BellOutline,
  BulbOutline,
  RightOutline,
  LogoutOutline,
  ReadOutline,
  MoreOutline,
  AppstoreOutline,
  CheckCircleOutline,
  ClockCircleOutline,
  CloseCircleOutline,
  PicCenterOutline,
  ContainerOutline,
  FlagOutline,
  PlusOutline,
  SyncOutline,
  HomeOutline,
  FileSearchOutline,
  SolutionOutline,
  MessageOutline,
  CloseOutline,
  ArrowsAltOutline,
  MinusOutline,
  MenuOutline,
  ClearOutline,
  BookOutline,
} from '@ant-design/icons-angular/icons';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, protectedResources } from './auth-config';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
const icons: IconDefinition[] = [
  BookOutline,
  ClearOutline,
  MenuOutline,
  LockOutline,
  UserOutline,
  EyeInvisibleOutline,
  WindowsFill,
  DashboardOutline,
  InboxOutline,
  AuditOutline,
  WarningOutline,
  SafetyOutline,
  DotChartOutline,
  FileProtectOutline,
  DollarOutline,
  AppstoreAddOutline,
  SettingOutline,
  BellOutline,
  BulbOutline,
  RightOutline,
  LogoutOutline,
  ReadOutline,
  MoreOutline,
  AppstoreOutline,
  CheckCircleOutline,
  ClockCircleOutline,
  CloseCircleOutline,
  PicCenterOutline,
  ContainerOutline,
  FlagOutline,
  PlusOutline,
  SyncOutline,
  HomeOutline,
  FileSearchOutline,
  UserOutline,
  SolutionOutline,
  MessageOutline,
  CloseOutline,
  ArrowsAltOutline,
  MinusOutline,
];

registerLocaleData(localePt);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withPreloading(PreloadAllModules),
    ),
    importProvidersFrom(
      NzIconModule.forRoot(icons),
      MsalModule.forRoot(
        new PublicClientApplication(msalConfig),
        {
          interactionType: InteractionType.Redirect,
          authRequest: {
            scopes: protectedResources.prodApi.scopes,
          },
        },
        {
          interactionType: InteractionType.Redirect,
          protectedResourceMap: new Map([
            [
              protectedResources.localApi.endpoint,
              protectedResources.localApi.scopes,
            ],
            [
              protectedResources.hmlApi.endpoint,
              protectedResources.hmlApi.scopes,
            ],
            [
              protectedResources.prodApi.endpoint,
              protectedResources.prodApi.scopes,
            ],
          ]),
        },
      ),
      NzNotificationModule,
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    { provide: NZ_I18N, useValue: pt_BR },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
  ],
};
